@use "./variables.scss" as *;

:export {
    gray100: $gray-100;
    gray90: $gray-90;
    gray80: $gray-80;
    gray70: $gray-70;
    gray60: $gray-60;
    gray50: $gray-50;
    gray40: $gray-40;
    gray30: $gray-30;
    gray20: $gray-20;
    gray10: $gray-10;
    maroon100: $maroon-100;
    maroon90: $maroon-90;
    maroon80: $maroon-80;
    maroon70: $maroon-70;
    maroon60: $maroon-60;
    maroon50: $maroon-50;
    maroon40: $maroon-40;
    maroon30: $maroon-30;
    maroon20: $maroon-20;
    maroon10: $maroon-10;
    red100: $red-100;
    red90: $red-90;
    red80: $red-80;
    red70: $red-70;
    red60: $red-60;
    red50: $red-50;
    red40: $red-40;
    red30: $red-30;
    red20: $red-20;
    red10: $red-10;
    forest100: $forest-100;
    forest90: $forest-90;
    forest80: $forest-80;
    forest70: $forest-70;
    forest60: $forest-60;
    forest50: $forest-50;
    forest40: $forest-40;
    forest30: $forest-30;
    forest20: $forest-20;
    forest10: $forest-10;
    green100: $green-100;
    green90: $green-90;
    green80: $green-80;
    green70: $green-70;
    green60: $green-60;
    green50: $green-50;
    green40: $green-40;
    green30: $green-30;
    green20: $green-20;
    green10: $green-10;
    white100: $white;
}