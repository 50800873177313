export * as AbletonLogo from "./ableton_logo.png";
export * as AngularLogo from "./angular_logo.png";
export * as AWSLogo from "./aws_logo.png";
export * as BlenderLogo from "./blender_logo.png";
export * as CPlusPlusLogo from "./c_plus_plus_logo.png";
export * as DjangoLogo from "./django_logo.png";
export * as DjangoRestLogo from "./django_rest_logo.png";
export * as FlutterLogo from "./flutter_logo.png";
export * as GraphQLLogo from "./graphql_logo.png";
export * as ParcelLogo from "./parcel_logo.png";
export * as PremiereProLogo from "./premiere_pro_logo.png";
export * as ReactLogo from "./react_logo.png";
export * as ThreeJSLogo from "./threejs_logo.png";
export * as TypeScriptLogo from "./typescript_logo.png";
